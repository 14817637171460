<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <c-table
          ref="itemTable"
          title="부품별 점검항목"
          topBorderClass="topcolor-orange"
          :columns="grid2.columns"
          :data="grid2.data"
          :merge="grid2.merge"
          :gridHeight="grid2.height"
          selection="multiple"
          :filtering="false"
          :usePaging="false"
          :editable="editable"
          :isExcelDown="false"
          :columnSetting="false"
          :expandAll="true"
          rowKey="checkItemId"
          :hideBottom="true"
        >
          <template slot="table-chip">
            <q-btn-group outline >
              <q-chip>
                  <q-avatar icon="push_pin" color="green" text-color="white" /> 
                  {{ "설비유형 : " + rowTypeName}}
              </q-chip>
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'checkItemName'">
              <c-text-column
                :editable="editable"
                :col="col"
                :props="props"
                @datachange="datachange(props, col)"
              >
              </c-text-column>
              <span>
                <div>
                  <q-btn-group outline class="ColumInnerBtnGroup">
                    <template v-for="(btn, idx) in col.btns">
                      <q-btn
                        :key="idx"
                        v-if="editable"
                        :label="btn.label"
                        :icon="btn.icon ? btn.icon : void 0"
                        :color="btn.color ? btn.color : 'blue-grey-4'"
                        :text-color="btn.textColor ? btn.textColor : 'white'"
                        class="ColumInnerBtn"
                        align="center"
                        @click.stop="innerBtnClicked(col, props, btn)">
                        <q-tooltip v-if="btn.tooltip">
                          <span v-html="btn.tooltip" />
                        </q-tooltip>
                      </q-btn>
                    </template>
                  </q-btn-group>
                </div>
              </span>
            </template>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && updateMode" label="설비유형별 점검항목 전체초기화" icon="autorenew" :showLoading="false" @btnClicked="reNewData" />
              <c-btn v-if="editable && updateMode" label="부품추가" icon="add" :showLoading="false" @btnClicked="addrow" />
              <c-btn v-if="editable && updateMode && grid2.data.length > 0" :showLoading="false" label="삭제" icon="remove" @btnClicked="remove"/>
              <c-btn
                v-if="editable && updateMode && grid2.data.length > 0"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="grid2.data"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="saveClass"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
        </c-table> 
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="itemTable5"
          title="UBM 항목/값"
          topBorderClass="topcolor-orange"
          :columns="grid5.columns"
          :data="grid5.data"
          :merge="grid5.merge"
          :gridHeight="grid5.height"
          :filtering="false"
          selection="multiple"
          :usePaging="false"
          :editable="editable"
          :expandAll="true"
          :isExcelDown="false"
          :columnSetting="false"
          :isFullScreen="false"
          rowKey="checkItemId"
          :hideBottom="true"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && updateMode" label="" icon="add" :showLoading="false" @btnClicked="addrow5" />
              <c-btn v-if="editable && updateMode && grid5.data.length > 0" :showLoading="false" label="" icon="remove" @btnClicked="remove5"/>
              <c-btn
                v-if="editable && updateMode && grid5.data.length > 0"
                :url="saveUrl"
                :isSubmit="isSave5"
                :param="grid5.data"
                mappingType="POST"
                label=""
                icon="save"
                @beforeAction="saveClass5"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
        </c-table> 
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <c-table
          ref="itemTable3"
          title="CBM Gauge 점검항목"
          topBorderClass="topcolor-orange"
          :columns="grid3.columns"
          :data="grid3.data"
          :merge="grid3.merge"
          :gridHeight="grid3.height"
          :filtering="false"
          selection="multiple"
          :usePaging="false"
          :editable="editable"
          :expandAll="true"
          :isExcelDown="false"
          :columnSetting="false"
          rowKey="checkItemId"
          :hideBottom="true"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && updateMode" label="CBM 항목추가" icon="add" :showLoading="false" @btnClicked="addrow3" />
              <c-btn v-if="editable && updateMode && grid3.data.length > 0" :showLoading="false" label="삭제" icon="remove" @btnClicked="remove3"/>
              <c-btn
                v-if="editable && updateMode && grid3.data.length > 0"
                :url="saveUrl"
                :isSubmit="isSave3"
                :param="grid3.data"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="saveClass3"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
        </c-table> 
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="itemTable4"
          title="CBM Characteristic"
          topBorderClass="topcolor-orange"
          :columns="grid4.columns"
          :data="grid4.data"
          :merge="grid4.merge"
          :gridHeight="grid4.height"
          :filtering="false"
          selection="multiple"
          :usePaging="false"
          :editable="editable"
          :expandAll="true"
          :isExcelDown="false"
          :columnSetting="false"
          :isFullScreen="false"
          rowKey="checkItemId"
          :hideBottom="true"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && updateMode" label="" icon="add" :showLoading="false" @btnClicked="addrow4" />
              <c-btn v-if="editable && updateMode && grid4.data.length > 0" :showLoading="false" label="" icon="remove" @btnClicked="remove4"/>
              <c-btn
                v-if="editable && updateMode && grid4.data.length > 0"
                :url="saveUrl"
                :isSubmit="isSave4"
                :param="grid4.data"
                mappingType="POST"
                label=""
                icon="save"
                @beforeAction="saveClass4"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
        </c-table> 
      </div>
    </div>
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">부품명</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="hiddenMstCd" autofocus @keyup.enter="setMst" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="취소" @click="cancelMst" />
          <q-btn flat label="추가" @click="setMst" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default 
  {
  name: 'equipment-item',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentTypeCd: '',
        equipmentName: '',
      }),
    },
  },
  data() {
    return {
      prompt: false,
      hiddenMstCd: '',
      grid2: {
        columns: [],
        height: '400px',
        data: [],
      },
      grid3: {
        columns: [],
        height: '300px',
        data: [],
      },
      grid4: {
        columns: [],
        height: '300px',
        data: [],
      },
      grid5: {
        columns: [],
        height: '400px',
        data: [],
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        dailyFlag: 'Y'
      },
      equipClassInsItemData: {
        equipmentType: '',
        equipmentCd: '',
        plantCd: '',
        equipmentCheckTypeCd: null,
        checkItemNo: '',
        checkItemNm: '',
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
      },
      editable: true,
      itemDetailUrl: '',
      deleteUrl: '',
      saveUrl: '',
      updateUrl: '',
      checkUrl: '',
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      checkMethodItems: [],
      isSave: false,
      isSave3: false,
      isSave4: false,
      isSave5: false,
      isDelete: false,
      isDelete3: false,
      isDelete4: false,
      isDelete5: false,
      updateMode: false,
      saveType: 'POST',
      removeMode: false,
      key: {
        equipmentTypeCd: '',
        plantCd: '',
        equipmentCheckTypeCd: null,
      },
      rowTypeName: '',
      rowCheckItemName: '',
      tempInsGridDetail: {
        equipmentTypeCd:  '',
        equipmentCd:  '',
        plantCd:  '',
        equipmentCheckTypeCd:  '',
        checkItemNo:  '',
        checkItemNm:  '',
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
      },
    };
  },
  watch: {

  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.itemDetailUrl = selectConfig.mdm.equipment.class.item.equip.list.url;
      this.saveUrl = transactionConfig.mdm.equipment.class.item.equip.save.url;
      this.deleteUrl = transactionConfig.mdm.equipment.class.item.equip.delete.url;

      this.$comm.getComboItems('MDM_CHECK_CYCLE_CD').then(_result => {
        this.grid2.columns = [
          {
            required: true,
            name: 'checkItemPartName',
            field: 'checkItemPartName',
            label: '부품',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목',
            btns: [
              { label: '', icon: 'add', color: 'orange', tooltip: '부품별 점검항목 추가' },
            ],
            style: 'width:250px',
            type: 'custom',
            align: 'left',
            sortable: false,
          },
          {
            name: 'checkItemMethod',
            field: 'checkItemMethod',
            label: '점검방법',
            style: 'width:200px',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            name: 'checkStandard',
            field: 'checkStandard',
            label: '점검기준',
            style: 'width:200px',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            required: true,
            name: 'checkItemCycleCd',
            field: 'checkItemCycleCd',
            label: '주기',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: _result,
            sortable: false,
          },
        ]
        this.grid2.merge =  [
          { index: 0, colName: "checkItemPartName" },
        ]
        this.grid3.columns = [
          {
            required: true,
            name: 'checkItemName',
            field: 'checkItemName',
            label: '항목',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            required: true,
            name: 'checkItemCycleCd',
            field: 'checkItemCycleCd',
            label: '주기',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: _result,
            sortable: false,
          },
          {
            required: true,
            name: 'gaugeWarnUcl',
            field: 'gaugeWarnUcl',
            label: '경고상한값',
            style: 'width:150px',
            type: 'number',
            sortable: false,
          },
          {
            required: true,
            name: 'gaugeWarnLcl',
            field: 'gaugeWarnLcl',
            label: '경고하한값',
            style: 'width:150px',
            type: 'number',
            sortable: false,
          },
          {
            required: true,
            name: 'gaugeExecUcl',
            field: 'gaugeExecUcl',
            label: '실행상한값',
            style: 'width:150px',
            type: 'number',
            sortable: false,
          },
          {
            required: true,
            name: 'gaugeExecLcl',
            field: 'gaugeExecLcl',
            label: '실행하한값',
            style: 'width:150px',
            type: 'number',
            sortable: false,
          },
        ]
        this.grid4.columns = [
          {
            required: true,
            name: 'checkItemName',
            field: 'checkItemName',
            label: '항목',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            required: true,
            name: 'checkStandard',
            field: 'checkStandard',
            label: '값',
            style: 'width:120px',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            required: true,
            name: 'checkItemCycleCd',
            field: 'checkItemCycleCd',
            label: '주기',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: _result,
            sortable: false,
          },
        ]
        this.grid5.columns = [
          {
            required: true,
            name: 'checkItemName',
            field: 'checkItemName',
            label: '항목',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            required: true,
            name: 'checkStandard',
            field: 'checkStandard',
            label: '값',
            style: 'width:120px',
            type: 'text',
            align: 'left',
            sortable: false,
          },
        ]
        this.rowClick();
      })
    },
    rowClick() {
      this.rowTypeName = this.param.equipmentTypeName;
      this.key.equipmentCd = this.param.equipmentCd;
      this.key.plantCd = this.param.plantCd;
      this.updateMode = true;
      // 설비유형별 점검항목 조회      
      this.$http.param = {checkItemPartCd: 'CIPC000001', equipmentCd: this.param.equipmentCd}; // 설비부품별 점검
      this.$http.url = this.itemDetailUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid2.data = this.$_.clone(_result.data);
      },);
      // 설비유형별 점검항목 조회(CBM gauge)      
      this.$http.param = {checkItemPartCd: 'CIPC000002', equipmentCd: this.param.equipmentCd}; // CBM gauge
      this.$http.url = this.itemDetailUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid3.data = this.$_.clone(_result.data);
      },);
      // 설비유형별 점검항목 조회(CBM gauge)      
      this.$http.param = {checkItemPartCd: 'CIPC000003', equipmentCd: this.param.equipmentCd}; // CBM Characteristic
      this.$http.url = this.itemDetailUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid4.data = this.$_.clone(_result.data);
      },);
      // 설비유형별 점검항목 조회(UBM)      
      this.$http.param = {checkItemPartCd: 'CIPC000004', equipmentCd: this.param.equipmentCd}; // UBM
      this.$http.url = this.itemDetailUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid5.data = this.$_.clone(_result.data);
      },);
    },
    addrow() {
      this.prompt = true;
    },
    remove() {
      let selectData = this.$refs['itemTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.grid2.data = this.$_.reject(this.grid2.data, { checkItemId: item.checkItemId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.rowClick(null);
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveClass() {
      let isConti = true;
      let checkItem = ['checkItemPartName', 'checkItemCycleCd']
      this.$_.forEach(this.grid2.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      }); 
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [부품명, 주기]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrow3() {
      this.grid3.data.push({
        equipmentCd: this.key.equipmentCd,
        plantCd: this.key.plantCd,
        checkItemId: uid(),
        equipmentCheckTypeCd: null,
        checkItemPartCd: 'CIPC000002', // CBM Gauge
        checkItemPartName: '',
        checkItemCycleCd: null,
        checkItemName: '',
        checkItemMethod: '',
        checkStandard: '',
        sortOrder: this.grid3.data.length == 0 ? 1 : (this.grid3.data.length + 1) * 10,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    remove3() {
      let selectData = this.$refs['itemTable3'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.grid3.data = this.$_.reject(this.grid3.data, { checkTypeItemId: item.checkTypeItemId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.rowClick(null);
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveClass3() {
      let isConti = true;
      let checkItem = ['checkItemName','checkItemCycleCd','gaugeWarnUcl','gaugeWarnLcl','gaugeExecUcl','gaugeExecLcl']
      this.$_.forEach(this.grid3.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      }); 
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [전체]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave3 = !this.isSave3;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrow4() {
      this.grid4.data.push({
        equipmentCd: this.key.equipmentCd,
        plantCd: this.key.plantCd,
        checkItemId: uid(),
        equipmentCheckTypeCd: null,
        checkItemPartCd: 'CIPC000003', // CBM 
        checkItemPartName: '',
        checkItemCycleCd: null,
        checkItemName: '',
        checkItemMethod: '',
        checkStandard: '',
        sortOrder: this.grid4.data.length == 0 ? 1 : (this.grid4.data.length + 1) * 10,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    remove4() {
      let selectData = this.$refs['itemTable4'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.grid4.data = this.$_.reject(this.grid4.data, { checkTypeItemId: item.checkTypeItemId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.rowClick(null);
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveClass4() {
      let isConti = true;
      let checkItem = ['checkItemName','checkStandard','checkItemCycleCd']
      this.$_.forEach(this.grid4.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      }); 
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [전체]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave4 = !this.isSave4;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrow5() {
      this.grid5.data.push({
        equipmentCd: this.key.equipmentCd,
        plantCd: this.key.plantCd,
        checkItemId: uid(),
        equipmentCheckTypeCd: null,
        checkItemPartCd: 'CIPC000004', // UBM
        checkItemPartName: '',
        checkItemCycleCd: null,
        checkItemName: '',
        checkItemMethod: '',
        checkStandard: '',
        sortOrder: this.grid5.data.length == 0 ? 1 : (this.grid5.data.length + 1) * 10,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    remove5() {
      let selectData = this.$refs['itemTable5'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.grid5.data = this.$_.reject(this.grid5.data, { checkTypeItemId: item.checkTypeItemId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.rowClick(null);
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveClass5() {
      let isConti = true;
      let checkItem = ['checkItemName','checkStandard']
      this.$_.forEach(this.grid5.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      }); 
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [전체]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave5 = !this.isSave5;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.rowClick()
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    innerBtnClicked(col, props) {
      this.grid2.data.splice(props.rowIndex+1, 0, {
        equipmentCd: this.key.equipmentCd,
        plantCd: this.key.plantCd,
        checkItemId: uid(),
        equipmentCheckTypeCd: null,
        checkItemPartCd: 'CIPC000001', // 설비부품별 점검
        checkItemPartName: props.row.checkItemPartName,
        checkItemCycleCd: null,
        checkItemName: '',
        checkItemMethod: '',
        checkStandard: '',
        sortOrder: Number(props.row.sortOrder + 1),
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    cancelMst() {
      this.hiddenMstCd = '';
      this.prompt = false;
    },
    setMst() {
      this.prompt = false;
      this.grid2.data.push({
        equipmentCd: this.key.equipmentCd,
        plantCd: this.key.plantCd,
        checkItemId: uid(),
        equipmentCheckTypeCd: null,
        checkItemPartCd: 'CIPC000001', // 설비부품별 점검
        checkItemPartName: this.hiddenMstCd,
        checkItemCycleCd: null,
        checkItemName: '',
        checkItemMethod: '',
        checkStandard: '',
        sortOrder: this.grid2.data.length == 0 ? 1 : (this.grid2.data.length + 1) * 10,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
      this.hiddenMstCd = '';
    },
    reNewData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '현재 부품별 점검항목/CBM/UBM 항목을 삭제하고\r\n설비유형별 점검항목/CBM/UBM 으로\r\n전체 초기화 하시겠습니까?',
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = transactionConfig.mdm.equipment.class.item.equip.reset.url;
          this.$http.type = 'PUT';
          this.$http.param = {
            equipmentCd: this.param.equipmentCd,
            equipmentTypeCd: this.param.equipmentTypeCd,
            regUserId: this.$store.getters.user.userId,
          };
          this.$http.request(() => {
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '초기화 되었습니다.', /* 저장되었습니다. */
              type: 'success', // success / info / warning / error
            });
            this.rowClick();
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    }
  }
};
</script>
